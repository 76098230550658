
<i18n lang="yaml">
pt:
  searchPlaceholder: 'Encontrar páginas, automações ou bases de dados'
en:
  searchPlaceholder: 'Find pages, workflows or sheets'
</i18n>

<template>
  <TheTopbar
    dummy-data-alert-enabled
    outlined
    class="studio-topbar"
    with-logo
    :transparent="transparent"
    :home-route="transparent ? appStore : dataRoute"
  >
    <template #start-content>
      <PagesMenu
        v-if="!hideSearch"
        :transparent="transparent"
      />
    </template>

    <template #center-content>
      <v-slide-x-transition>
        <PagesMenu
          v-if="hideSearch"
          :transparent="transparent"
        />
        <SearchInput
          v-else
          :placeholder="t('searchPlaceholder')"
          :searchable-data="searchableData"
        />
      </v-slide-x-transition>
    </template>
  </TheTopbar>
</template>

<script>
import { mapState } from '~/assets/javascript/modules/vuex';
import TheTopbar from '~/components/topbars/the-topbar';
import SearchInput from '~/components/search-input';
import { SearchableItemBuilder, SheetItemBuilder, ViewItemBuilder, WorkflowItemBuilder } from '~/assets/javascript/utils';
import PagesMenu from './_pages-menu';

export default defineComponent({
  name: 'StudioTopbar',
  components: {
    TheTopbar,
    PagesMenu,
    SearchInput,
  },
  props: {
    transparent: { type: Boolean, default: false },
    hideSearch: { type: Boolean, default: false },
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('builderView', ['views']),
      ...mapState('viewOptions', ['sheetsCache']),
      ...mapState('workflows', ['workflows']),
    };
  },
  data() {
    return {
      appStore: this.localeRoute({ name: 'store' }),
      dataRoute: this.localeRoute({ name: 't-tenantSlug-studio-data', params: { tenantSlug: this.$route.params.tenantSlug } }),
    };
  },
  computed: {
    // TODO: Do not get views, sheets and workflows in appstore
    searchableResourcesBuilders() {
      const builderParams = {
        localeRoute: this.localeRoute,
        $route: this.$route,
        currentEnvironment: 'studio',
      };
      return {
        view: new ViewItemBuilder(builderParams),
        sheet: new SheetItemBuilder(builderParams),
        workflow: new WorkflowItemBuilder(builderParams),
      };
    },
    searchableItemBuilder() {
      return Object.keys(this.searchableResourcesBuilders).reduce((acc, key) => {
        acc[key] = new SearchableItemBuilder(this.searchableResourcesBuilders[key]);
        return acc;
      }, {});
    },
    searchableData() {
      const searchableViews = this.searchableItemBuilder.view.buildItems(this.views);
      const searchableSheets = this.searchableItemBuilder.sheet.buildItems(this.sheetsCache);
      const searchableWorkflows = this.searchableItemBuilder.workflow.buildItems(this.workflows);

      return [...searchableViews, ...searchableSheets, ...searchableWorkflows];
    },
  },
});
</script>
<style lang="scss">
@use '~/assets/styles/tools/zazos-background' as bg;

// Not sure if the best way to scope it only under the appstore page, but it works for now
.zazos-home .studio-topbar {
  @include bg.zazos-background($important: true); // v-app override
  background-color: var(--z-theme-surface) !important;
}
</style>
